import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import servicesPic1 from "../../images/services/pic1.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";

class FreeOffersDerails extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Free & Easy</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											{/* <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li> */}
											<li className="breadcrumb-item active text-white" aria-current="page">
                                            Advanced Practice Management Software on the House When you Use our Billing Services!
                                            </li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 mb-30">
									<div className="ttr-media mb-30">
										{/* <img src={servicesPic1} className="rounded" alt=""/> */}
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Advanced Practice Management Software on the House When you Use our Billing Services!
</h2>
											{/* <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
										</div>
										<div className="row align-items-center">
											<div className="col-md-12 mb-30">
												<ul className="list-check-squer mb-0">
													<li>Would you like to streamline your practice with new technologies, FREE?
</li>
													{/* <li>That’s just a little bit more than</li> */}
	
	
	
	
	
	
<li>Can you currently track every dollar on every claim you process?  

</li>
													{/* <li>That’s just a little bit more than</li> */}
													<li>Are you happy with your current rate of collections?  

</li>
													<li>Are you in control of your receivables?</li>
												</ul>
											</div>
											{/* <div className="col-md-6 mb-30">
												<div className="skillbar-box mb-30">
													<h6 className="title">Advanced Technology</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "96%"}}></p>
														<span className="skill-bar-percent">96%</span>
													</div>
												</div>
												<div className="skillbar-box mb-30">
													<h6 className="title">Certified Engineers</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "79%"}}></p>
														<span className="skill-bar-percent">79%</span>
													</div>
												</div>
												<div className="skillbar-box mb-0">
													<h6 className="title">6 years Experience</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "75%"}}></p>
														<span className="skill-bar-percent">75%</span>
													</div>
												</div>
											</div> */}
										</div>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Increase Reimbursements, Automate, Cut Costs, Stay In Control...
</h4>
											<p className="mb-0">When you sign up with our Medical Billing Service we guarantee maximum reimbursements and lowest fees. Additionally, your practice will benefit from Free access to our advanced MedOffice Practice Management software!
Login securely 24/7 to use our advanced scheduler, point-of-sale system, and more.
View detailed productivity reports, check status on every claims...and more!
Also, MedOffice has robust connectivity to major EMR systems via advanced HL7 Link! View MedOffice Features.
This is the Best of Many Worlds... You maintain control, cut cost on software, training, support, and enjoy faster cash flow with maximized reimbursements and timely billing.

Free yourself to do what you do best and let's manage your billing needs with maximum efficiency and transparency.

Please contact us to see how you can advantage of this fantastic offer!

</p>
										</div>
										{/* <Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>How Doctor Can Ease Your Pain?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>How do I withdraw from a subject?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="2">
												<Accordion.Header>Understand Doctor Before You Regret?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="3">
												<Accordion.Header>What types of systems do you support?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="4">
												<Accordion.Header>We Teach You How To Feel Better?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="5">
												<Accordion.Header>How Can I Contact You?</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion> */}
									</div>
								</div>
								{/* <div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-detail"><span>Engine Diagnostics</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Lube Oil and Filters</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Belts and Hoses</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Air Conditioning</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Brake Repair</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Tire and Wheel Services</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<div className="brochure-bx">
												<h5 className="title-head">Download</h5>
												<Link to="#" className="download-link">
													<img src={pdf} alt=""/>
													<h5 className="title">Download our Brochures</h5>
													<span>Download</span>
												</Link>
												<Link to="#" className="download-link">
													<img src={doc} alt=""/>
													<h5 className="title">Our Company Details</h5>
													<span>Download</span>
												</Link>
											</div>
										</div>
										
									</aside>
								</div> */}
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default FreeOffersDerails;