import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Images
import aboutThumb1 from "../../images/about/pic-1.jpg";
import aboutThumb2 from "../../images/about/pic-2.jpg";
import aboutThumb3 from "../../images/about/pic-3.jpg";
import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <section className="section-sp1 about-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="about-thumb-area">
                  <ul>
                    <li>
                      <img className="about-thumb1" src={aboutThumb1} alt="" />
                    </li>
                    <li>
                      <img className="about-thumb2" src={aboutThumb2} alt="" />
                    </li>
                    <li>
                      <img className="about-thumb3" src={aboutThumb3} alt="" />
                    </li>
                    <li>
                      <div className="exp-bx">
                        30<span>Year Experience</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx">
                  <h6 className="title-ext text-secondary">About Us</h6>
                  <p>
                    Doctor’s Advantage is a practice management consulting
                    company specializing in medical and dental billing services
                    with the primary objective of maximizing efficiency and
                    optimizing revenue for all healthcare providers. We offer a
                    variety of solutions so healthcare providers are empowered
                    to do what they do best – provide quality healthcare.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature1">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="80px"
                            viewBox="0 0 24 24"
                            width="80px"
                            fill="#0E4777"
                          >
                            <g>
                              <rect fill="none" height="24" width="24" />
                              <path d="M17,2H7C5.9,2,5,2.9,5,4v2c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V4C19,2.9,18.1,2,17,2z M17,6H7V4h10V6z M20,22H4 c-1.1,0-2-0.9-2-2v-1h20v1C22,21.1,21.1,22,20,22z M18.53,10.19C18.21,9.47,17.49,9,16.7,9H7.3c-0.79,0-1.51,0.47-1.83,1.19L2,18 h20L18.53,10.19z M9.5,16h-1C8.22,16,8,15.78,8,15.5C8,15.22,8.22,15,8.5,15h1c0.28,0,0.5,0.22,0.5,0.5C10,15.78,9.78,16,9.5,16z M9.5,14h-1C8.22,14,8,13.78,8,13.5C8,13.22,8.22,13,8.5,13h1c0.28,0,0.5,0.22,0.5,0.5C10,13.78,9.78,14,9.5,14z M9.5,12h-1 C8.22,12,8,11.78,8,11.5C8,11.22,8.22,11,8.5,11h1c0.28,0,0.5,0.22,0.5,0.5C10,11.78,9.78,12,9.5,12z M12.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,15.78,12.78,16,12.5,16z M12.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,13.78,12.78,14,12.5,14z M12.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,11.78,12.78,12,12.5,12z M15.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,15.78,15.78,16,15.5,16z M15.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,13.78,15.78,14,15.5,14z M15.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,11.78,15.78,12,15.5,12z" />
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">Billing Services</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature2">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="80px"
                            viewBox="0 0 24 24"
                            width="80px"
                            fill="#0E4777"
                          >
                            <path d="M0,0h24v24H0V0z" fill="none" />
                            <g>
                              <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" />
                              <rect height="2" width="6" x="9" y="7" />
                              <rect height="2" width="2" x="16" y="7" />
                              <rect height="2" width="6" x="9" y="10" />
                              <rect height="2" width="2" x="16" y="10" />
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">EMR / EHR Software</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature3">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="80px"
                            viewBox="0 0 24 24"
                            width="80px"
                            fill="#0E4777"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
                          </svg>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">CPT & Billing Analysis</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature4">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="80px"
                            viewBox="0 0 24 24"
                            width="80px"
                            fill="#0E4777"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z" />
                          </svg>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">Office Support</h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Link to="/about-us" className="btn btn-primary shadow">
                  Read More
                </Link> */}
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
          <img className="pt-img5 animate2" src={ptImg5} alt="" />
        </section>
      </>
    );
  }
}

export default aboutSection;
