import React, { Component } from "react";

class LatestNewsSection extends Component {
  render() {
    return (
      <>
        <section className="section-sp1 service-wraper2">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-sm-4 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">30</h2>
                  <h5 className="ttr-title">years in Business</h5>
                  {/* <p>
                    Etiam ante ante, molestie vitae cursus ac, pharetra euismod
                    libero.
                  </p> */}
                </div>
              </div>
              <div className="col-xl-4 col-sm-4 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">A+</h2>
                  <h5 className="ttr-title">BBB Rating & Accreditation</h5>
                  {/* <p>
                    Etiam ante ante, molestie vitae cursus ac, pharetra euismod
                    libero.
                  </p> */}
                </div>
              </div>
              <div className="col-xl-4 col-sm-4 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">Top 10</h2>
                  <h5 className="ttr-title">
                    Medical Practice Management Solution Providers (2019)
                  </h5>
                  {/* <p>
                    Etiam ante ante, molestie vitae cursus ac, pharetra euismod
                    libero.
                  </p> */}
                </div>
              </div>
              {/* <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">800</h2>
                  <h5 className="ttr-title">Satisfied Client</h5>
                  <p>
                    Etiam ante ante, molestie vitae cursus ac, pharetra euismod
                    libero.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LatestNewsSection;
