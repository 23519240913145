
import firebase from "firebase";
import "firebase/storage";

const firebaseDevelopment = {
  apiKey: "AIzaSyB4_YbGtBMMmdcbMso1KWxGwbCvaU_EoBI",
  authDomain: "meditro-9752c.firebaseapp.com",
  projectId: "meditro-9752c",
  storageBucket: "meditro-9752c.appspot.com",
  messagingSenderId: "103368596078",
  appId: "1:103368596078:web:bc2e100e39d99ac09e647f",
  measurementId: "G-B061M5GF9D"
};

// Initialize Firebase
// firebase.initializeApp(
//   process.env.REACT_APP_ENVIRONMENT === 'development'
//     ? firebaseDevelopment
//     : firebaseProduction,
// )
firebase.initializeApp(firebaseDevelopment);

firebase.analytics();

const storage = firebase.storage();

export { firebase, storage };
