import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Col, Row,message } from 'antd';


// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
import animateRotate from "../../images/shap/plus-blue.png";
import emailjs from "emailjs-com";

// import { Button, Checkbox } from "antd";
import { Checkbox } from "antd";  
const emailServiceConfig = {
  serviceID: "service_1i2ryzq", 
  templateID: "template_xosro0b", 
  userID: "OE__t6fGuIYBEVsbx", 
};


class ContactUs extends Component {
  handleFormSubmit = async (values) => {
    // console.log(values)
    try {
      // Prepare the email message
      const message = `
        Practice: ${values.practiceName}
        Name: ${values.name}
        Title: ${values.title}
        Email: ${values.email}
        Phone: ${values.phone}
        Selected Services: ${values.selectedServices.join(", ")}
      `;

      // Send the email using EmailJS
      await emailjs.send(
        emailServiceConfig.serviceID,
        emailServiceConfig.templateID,
        {
          to_email: values.email, // Replace with the recipient's email address
          from_name: values.name,
          message: message,
        },
        emailServiceConfig.userID
      );
      this.formRef.current.resetFields();


      console.log("Email sent successfully");
      message.success("Form submitted successfully!");

      // You can add any success handling here, e.g., show a success message
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle any errors that occur during the send process
    }
  };
  formRef = React.createRef();

  
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner banner-lg contact-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Contact Us</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                        
                      >
                        Contact Us
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={animateWave} alt="" />
              <img className="pt-img2 animate2" src={animate2} alt="" />
              <img
                className="pt-img3 animate-rotate"
                src={animateRotate}
                alt=""
              />
            </div>
          </div>

          <section className=""
          >
            <div className="container">
              <div className="contact-wraper">
                <div className="row">
                  <div className="col-lg-6 mb-30">
                  <Form onFinish={this.handleFormSubmit} className="form-wraper contact-form ajax-form"
                                        ref={this.formRef}

                  >
  <div className="ajax-message"></div>
  <Form.Item name="practiceName" rules={[{ required: true, message: 'Please enter your practice name' }]}>
    <Input placeholder="Practice" />
  </Form.Item>
  <Form.Item name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
    <Input placeholder="Name" />
  </Form.Item>
  <Form.Item name="title" rules={[{ required: true, message: 'Please enter your title' }]}>
    <Input placeholder="Title" />
  </Form.Item>
  <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
    <Input type="email" placeholder="Email" />
  </Form.Item>
  <Form.Item name="phone" rules={[{ required: true, message: 'Please enter your phone number' }]}>
    <Input placeholder="Phone Number" />
  </Form.Item>
  <p>
    {" "}
    Select which of the following services you wish to receive information on:
  </p>
  <Form.Item name="selectedServices">
  <Checkbox.Group>
    <Row gutter={[16, 16]}> {/* Adjust gutter size as needed */}
      <Col md={12} xs={24}>
        <Checkbox value="Billing/Practice Management Services">
          Billing/Practice Management Services
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Interested in Medical Software">
          Interested in Medical Software
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Collection Services">
          Collection Services
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Interested in Dental Software">
          Interested in Dental Software
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Electronic Fund Transfer (EFT)">
          Electronic Fund Transfer (EFT)
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Interested in EMR Software">
          Interested in EMR Software
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Well-Care Services">
          Well-Care Services
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Billing Codes and Cost Analysis">
          Billing Codes and Cost Analysis
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Transcription Services">
          Transcription Services
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Patient Assessment (P.A.S)">
          Patient Assessment (P.A.S)
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Document Management Services">
          Document Management Services
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Custom Wellness Program">
          Custom Wellness Program
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Secure Remote Backup Services (RBS)">
          Secure Remote Backup Services (RBS)
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="MD Audit Shield - RAC">
          MD Audit Shield - RAC
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="CodeMAXX Services">
          CodeMAXX Services
        </Checkbox>
      </Col>
      <Col md={12} xs={24}>
        <Checkbox value="Physician Credentialing">
          Physician Credentialing
        </Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
</Form.Item>
  <br />
  <Form.Item>
    <Button  style={{height:54}} htmlType="submit" className="btn w-100 btn-secondary btn-lg">
      Submit
    </Button>
  </Form.Item>
</Form>
                  </div>
                  <div className="col-lg-6 mb-30">
                    <div
                      className="contact-info ovpr-dark"
                      style={{ backgroundImage: "url(" + pic1 + ")" }}
                    >
                      <div className="info-inner">
                        <h4 className="title mb-30">
                          Contact Us For Any Questions
                        </h4>
                        <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-map-alt"></i>Location
                          </h6>
                          <p>
                            {" "}
                            66 West Flagler Street – Suite 900 Miami, FL 33130
                          </p>
                        </div>
                        <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-id-badge"></i>Email &amp; Phone
                          </h6>
                          <Link to="#" className="text-white">
                            info@doc-adv.com
                          </Link>
                          <p>(+1) 883- 362-7238</p>
                        </div>
                        <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-world"></i>Follow Us
                          </h6>
                          <ul className="social-media">
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://twitter.com/"
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.linkedin.com/"
                              >
                                <i className="fab fa-linkedin"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.instagram.com/"
                              >
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature4">
                    <div className="icon-md feature-icon">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Contact Number</h5>
                      <p>(833) 362-7238</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature3">
                    <div className="icon-md feature-icon">
                      <img src={icon3} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Email Address</h5>
                      <p>info@doc-adv.com</p>
                      {/* <p>example@support.com</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature2">
                    <div className="icon-md feature-icon">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Address</h5>
                      <p>
                        Location 66 West Flagler Street – Suite 900 Miami, FL
                        33130
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ContactUs;
