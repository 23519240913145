import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Import Images
import testShape from "../../images/testimonials/shape.png";
import testPic1 from "../../images/testimonials/pic1.jpg";
import testPic2 from "../../images/testimonials/pic2.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import testPic4 from "../../images/testimonials/pic4.jpg";
import testPic5 from "../../images/testimonials/pic5.jpg";
import testPic6 from "../../images/testimonials/pic6.jpg";
import plusOrange from "../../images/shap/plus-orange.png";
import squareBlue from "../../images/shap/square-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import circleOrange2 from "../../images/shap/circle-orange-2.png";

class testimonialSection extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        <section className="section-area section-sp3 testimonial-wraper">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-secondary">Free Offers</h6>
              <h2 className="title m-b0">
                {/* Free Software<br /> */}
                <Link className="text-decoration-underline" to="https://www.doc-adv.com/FreeAnalysis">

                  Free Software & Cost Analysis
                </Link>
              </h2>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 text-center">
                <div className="">
                  <img className="bg-img" src={require('../../images/MBS_Online_Version.png')} alt="" />

                </div>
              </div>
              <div className="col-lg-6">
                <Slider {...settings} className="testimonial-slide">
                  <div className="slider-item">
                    <div className="testimonial-bx">
                      <div className="testimonial-content">

                        <p>
                          <Link className="text-decoration-underline" to="/free-offers-detail">
                            Click Here {' '}
                          </Link>
                          for details on how to receive FREE State-of-the-Art Practice Management Software.
<br/>
                         Are you losing money because of inaccurate billing? Enjoy free CPT and Billing Cost Analysis. We'll provide you with a detailed report indicating any possible loss of revenue.
                       </p>
                      </div>
                      <div className="client-info">
                        {/* <h5 className="name">John Deo</h5>
                        <p>patient</p> */}
                      </div>
                      {/* <div className="quote-icon">
                        <i className="fas fa-quote-left"></i>
                      </div> */}
                    </div>
                  </div>


                </Slider>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate1" src={plusOrange} alt="" />
          <img className="pt-img2 animate2" src={squareBlue} alt="" />
          <img className="pt-img3 animate3" src={circleDots} alt="" />
          <img className="pt-img4 animate4" src={circleOrange2} alt="" />
        </section>
      </>
    );
  }
}

export default testimonialSection;
