import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

// Import Images
import bnrImg1 from '../../images/banner/img1.jpg';
import waveBlue from '../../images/shap/wave-blue.png';
import circleDots from '../../images/shap/circle-dots.png';
import plusBlue from '../../images/shap/plus-blue.png';
import servicesPic1 from '../../images/services/pic1.jpg';
import pdf from '../../images/icon/pdf.png';
import doc from '../../images/icon/doc.png';
import { firebase } from "../../firebase/config";

function ServiceDetail(props) {
  // You can use useState for managing state variables
  const [activeKey, setActiveKey] = useState('0');
  const [serviceDetail, setServiceDetail] = useState();

  const { serviceId,title } = useParams();
  //   const { data } = props;
  // const  state  = useLocation();
  const { state } = useLocation();

  console.log(state)

  const getServicesDetails = async () => {
    try {
      const serviceRef = firebase.firestore().collection('serviceDetails');
      const querySnapshot = await serviceRef.where('serviceId', '==', serviceId).get();
  
      if (!querySnapshot.empty) {
        // Service details found, handle the data as needed
        const serviceData = querySnapshot.docs[0].data(); // Assuming there's only one matching document
        setServiceDetail(serviceData);
        console.log('Service Details:', serviceData);
      } else {
        console.log('Service not found');
      }
    } catch (error) {
      console.error('Error fetching service details:', error);
    }
  };
  useEffect(() => {
    // Call the function to fetch service details with the serviceId parameter
    getServicesDetails(serviceId);
  }, [serviceId]);

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div className="page-banner" style={{ backgroundImage: `url(${bnrImg1})` }}>
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>{title}</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{' '}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Service Details
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-30">
                <div className="ttr-media mb-30">
                  <img src={serviceDetail?.image} className="rounded w-100" alt="" />
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h2 className="title mb-15">{serviceDetail?.section2Heading}</h2>
                    <p className="mb-0">
                      {/* {serviceDetail?.section2Paragraph} */}
                      <div style={{whiteSpace: "pre-line"}}>{serviceDetail?.section2Paragraph}</div>

                    </p>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-12 mb-30">
                      <ul className="list-check-squer mb-0">

                        {serviceDetail?.checkboxData  &&serviceDetail?.checkboxData?.map((el) => {
                          return (
                            <li key={el}>{el.checkboxTitle}</li>

                          )
                        })}
                      </ul>
                    </div>

                  </div>
                  <p className="mb-0">
                      {/* {serviceDetail?.section2Paragraph} */}
                      <div style={{whiteSpace: "pre-line"}}>{serviceDetail?.section3Paragraph}</div>

                    </p>
                </div>
                <div className="clearfix mt-4">
                  {/* <div className="head-text mb-30">
                    <h4 className="title mb-10">Benefits</h4>
                    <p className="mb-0">
                      {state.befiftPara}
                    </p>
                  </div> */}
                  <Accordion defaultActiveKey={activeKey} className="accordion ttr-accordion1 mt-3">

                    {serviceDetail?.carouselData
?.map((el, ind) => {
                      return (
                        <Accordion.Item eventKey={ind}>
                          <Accordion.Header onClick={() => setActiveKey(ind)}>{el.carouselTitle}</Accordion.Header>
                          <Accordion.Body>
                            <p className="mb-0">
                              {
                                el.carouselDescription
                              }
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    })}



                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ServiceDetail;